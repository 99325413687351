<template>
  <div class="drop-down-select" :class="openDropDownShadow">
    <div class="no-select" @click="openSelectList">
      <div class="value">{{ showValue }}</div>
      <img class="arrow-image" :src="arrowDirection"/>
    </div>
    <div id="drop_down" class="drop-down-main-list" :class="openDropDown">
      <div class="drop-down-main-list-item" :class="activateSelectItem(item)" @click="selectItem(item)" v-for="(item, index) in listData" :key="item.AnswerValue">{{ item.AnswerItem }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "DropDwonSelect",
    data(){
      return{
        openFlag: true,
      }
    },
    props:{
      listData: {
        type: Array,
        default: []
      },
      showValue: {
        type: String,
        default: '未选择'
      }
    },
    computed:{
      openDropDownShadow(){
        return this.openFlag?'activate-drop-down-shadow':''
      },
      openDropDown(){
        return this.openFlag?'activate-drop-down':''
      },
      arrowDirection(){
        return this.openFlag?require('@/assets/test/arrow-up.png'):require('@/assets/test/arrow-down.png')
      }
    },
    mounted(){
      document.getElementById('drop_down').scrollTop = 0
    },
    methods:{
      // 展开选择列表
      openSelectList(){
        this.openFlag = !this.openFlag
      },
      // 选择操作
      selectItem(item){
        this.$emit('select', item)
      },
      // 列表中被选中的状态
      activateSelectItem(item){
        return this.showValue === item.AnswerItem?'activate-item':''
      }
    }
  }
</script>

<style scoped lang="less">
  .drop-down-select{
    background-color: #ffffff;
    border-radius: 30px;
    .no-select{
      padding: 20px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      .value{
        margin-right: 10px;
        color: #606266;
      }
      .arrow-image{
        height: 10px;
      }
    }

    .drop-down-main-list{
      height: 0;
      overflow: auto;
      transition: height .15s linear;
      &::-webkit-scrollbar{
        background-color: transparent;
      }
      &-item{
        padding: 20px;
        font-size: 16px;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
      }
      .activate-item{
        color: rgb(54, 134, 226);
      }
    }
    .activate-drop-down{
      height: 250px;
    }
  }
  .activate-drop-down-shadow{
    box-shadow: 0 0 10px 0 rgba(249, 89, 64, 0.2);
  }

</style>
