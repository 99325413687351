<template>
  <div class="main-box">
    <div class="enter-content">
      <custom-frame title="升学路径测评" :testType="5"></custom-frame>
      <div class="title">{{ enterPathTitle }}</div>
      <div v-if="mainData.length !== 0" class="main">
        <div class="question">{{ mainData[indexNumber].Question }}{{ isMultiple }}</div>
        <div class="answer">
          <div class="next-box" v-if="answerStatus">
            <drop-down-select :list-data="mainData[indexNumber].AnswerList" :show-value="showValue" @select="handleSelect"></drop-down-select>
          </div>
          <div v-else>
            <div class="answer-item" :class="activateStatus(item, mainData[indexNumber])" @click="selectAnswer(item, mainData[indexNumber], 'next', index)" v-for="(item, index) in  mainData[indexNumber].AnswerList" :key="index">
              {{ item.AnswerItem }}
            </div>
          </div>
          <div v-show="showNextButton" class="next-question" :class="activateNext" @click="nextQuestion">{{ nextButtonText }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import API from '@/api/config'
  // import {UpgradePathQuestionList, UpgradePathTest} from '@/api/test/test'
  import CustomFrame from '@/views/test/custom-components/CustomFrame'
  import DropDownSelect from '@/views/test/custom-components/DropDownSelect'

  export default {
    components: {
      CustomFrame,
      DropDownSelect
    },
    name: "enter-content",
    data () {
      return {
        addFlag: true,
        only: false,
        nextFlag: false,
        mainData: [],
        indexNumber: 0,

        answerList: [],
        dropDownAnswer: {},
        showValue: '未选择'
      }
    },
    computed: {
      // 下一题按钮文字切换
      nextButtonText(){
        return this.indexNumber + 1 >= this.mainData.length?'完 成':'下一题'
      },
      // 是否展示下一题按钮
      showNextButton(){
        const tempItem = this.mainData[this.indexNumber]
        return tempItem.IsMultipleChoice || tempItem.AnswerList.length > 5
      },
      // 激活下一题按钮点击状态
      activateNext(){
        return this.nextFlag?'activate-next':''
      },
      // 答案展示形式
      answerStatus () {
        const tempItem = this.mainData[this.indexNumber]
        return tempItem.AnswerList.length > 5 && !tempItem.IsMultipleChoice
      },
      // 多选
      isMultiple(){
        return this.mainData.length!==0&&this.mainData[this.indexNumber].IsMultipleChoice?'（多选）':''
      },
      // 升学路径题号
      enterPathTitle () {
        const tempLength = this.mainData.length
        return this.indexNumber >= tempLength ? tempLength + ' / ' + tempLength : this.indexNumber + 1 + ' / ' + tempLength
      },
    },
    mounted () {

      this.initialize()
    },
    methods: {
      // 下一题
      nextQuestion(){
        if(this.nextFlag){
          if(this.indexNumber + 1 >= this.mainData.length){
            this.$ls.set('test-enterPath-answerList', this.answerList)
            this.$router.push({
              path: '/submit-test',
              query: {
                testType: '5'
              }
            })
          }else{
            if(this.answerStatus){
              this.selectAnswer(this.dropDownAnswer, this.mainData[this.indexNumber], '')
            }
            this.indexNumber++
          }
          // 获取子组件中的一个元素id
          let el = document.getElementById('drop_down') // 下拉选择组件中下拉滚动框
          el&&(el.scrollTop = 0) // 下拉滚动框置顶
          this.showValue = '未选择'
          this.nextFlag = false
          this.addFlag = true
        }
      },
      // 下拉单选选择
      handleSelect(query){
        this.showValue = query.AnswerItem
        this.dropDownAnswer = query
        this.nextFlag = true
      },
      // 展开单选选择
      selectAnswer (item, questionItem, next, index) {
        item = item || {}
        questionItem = questionItem || {}
        next = next || ''
        if(this.addFlag){
          this.addFlag = false
          if(questionItem.IsMultipleChoice){
            let tempItem = this.answerList.find(answerItem => answerItem.QuestionId === questionItem.ID)
            if(tempItem){
              const flag = tempItem.Answers.includes(item.AnswerValue)
              if(flag){
                tempItem.Answers.remove(item.AnswerValue)
              }else{
                if(index + 1 === this.mainData[this.indexNumber].AnswerList.length){
                  tempItem.Answers = [item.AnswerValue]
                  this.only = true
                }else{
                  if(this.only){
                    tempItem.Answers = [item.AnswerValue]
                    this.only = false
                  }else{
                    tempItem.Answers.push(item.AnswerValue)
                  }
                }
              }
            }else{
              tempItem = {
                QuestionId: questionItem.ID,
                Answers: [item.AnswerValue]
              }
              this.answerList.push(tempItem)
              index + 1 === this.mainData[this.indexNumber].AnswerList.length?this.only = true:this.only = false
            }
            tempItem.Answers.length > 0?this.nextFlag = true:this.nextFlag = false
            this.addFlag = true
          }else{
            this.answerList.push({
              QuestionId: questionItem.ID,
              Answers: [item.AnswerValue]
            })
          }
          if (next === 'next'&& !questionItem.IsMultipleChoice) {
            if(this.indexNumber + 1 >= this.mainData.length){
              this.$ls.set('test-enterPath-answerList', this.answerList)
              this.$router.push({
                path: '/submit-test',
                query: {
                  testType: '5'
                }
              })
            }else{
              setTimeout(() => {
                this.indexNumber++
                this.addFlag = true
              }, 300)
            }
          }
        }
      },

      initialize () {
        this.getEnterPathQuestionList()
      },

      async getEnterPathQuestionList () {
        this.mainData = (await API.Career.UpgradePathQuestionList()).data
      },
      // 答案选中状态
      activateStatus (showItem, questionItem) {
        const tempItem = this.answerList.find(item => item.QuestionId === questionItem.ID)
        if(tempItem){
          if(tempItem.Answers.includes(showItem.AnswerValue)){
            return 'activate-answer-status'
          }
        }
      },
    }
  }
</script>

<style scoped lang="less">
  .main-box{
    width: 100%;
    background: linear-gradient(to bottom, #F4D25E, #F95940);
  }
  .enter-content {
    max-width: 750px;
    margin: 0 auto;
    position: relative;
    border: 1px solid #eeefff;
    .title {
      width: 100%;
      padding: 20px 40px;
      color: rgba(68, 76, 240, 0.2);
      text-align: center;
      position: absolute;
      font-size: 20px;
      font-weight: bold;
      top: 120px;
      left: 0;
    }
    .main {
      box-sizing: border-box;
      width: 100%;
      padding: 40px;
      position: absolute;
      top: 150px;
      left: 0;
      .question {
        margin-bottom: 50px;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
      }
      .answer {
        text-align: center;
        height: 400px;
        position: relative;
        &-item {
          width: 80%;
          padding: 15px;
          margin: 15px auto;
          border-radius: 40px;
          background-color: rgba(168, 168, 187, 0.2);
          cursor: pointer;
          -webkit-tap-highlight-color: transparent;
        }
        .activate-answer-status{
          background: linear-gradient(to right, #FF823A, #FF735D);
          color: #ffffff;
        }
        .next-question{
          width: 180px;
          padding: 15px;
          margin: 16px auto;
          text-align: center;
          background-color: #A8A8BB;
          border-radius: 30px;
          color: #ffffff;
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
          cursor: pointer;
        }
        .activate-next{
          background: linear-gradient(to right, #f9904a, #F9573E);
        }
      }
    }
  }

</style>
